<template>
  <v-container class="container--fluid fill-height forgotpassword-page">
    <v-row no-gutters align="center" justify="center">
      <v-col cols="10" style="max-width: 500px">
        <v-card tile>
          <v-card-text v-show="!showNotification">
            <div class="layout column align-center">
              <h2
                class="text-center my-1 mb-5 text-uppercase font-weight-black"
                color="blue-darken-1"
              >
                3Nav - Definir acesso
              </h2>
            </div>
            <ResetForgotForm @success="success"></ResetForgotForm>
          </v-card-text>
          <v-card-text v-show="showNotification" class="mt-5">
            <div class="layout column align-center">
              <v-img src="@/assets/logo.png" alt="Logo" width="60" />
              <h2
                class="text-center my-1 mb-5 text-uppercase font-weight-black"
                color="blue-darken-1"
              >
                3Nav
              </h2>
            </div>
            <p class="text-body-1 text-center mt-5">
              A palavra-passe foi redefinida
            </p>
            <v-btn
              depressed
              text
              color="grey darken-2"
              exact
              large
              block
              @click="goToLogin"
            >
              Voltar ao login
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ResetForgotForm from "@/pages/Auth/ResetForgotForm";

export default {
  data: () => ({
    showNotification: false,
  }),
  components: {
    ResetForgotForm,
  },

  methods: {
    success() {
      this.showNotification = true;
    },
    goToLogin() {
      this.$router.push("/login");
    },
  },
};
</script>
<style>
.forgotpassword-page {
  background-image: url("../../assets/background.png");
  background-color: #1a202c !important;
  background-repeat: no-repeat;
  --background-attachment: fixed;
  background-position: bottom;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  transition: background-color 5000s ease-in-out 0s;
  font-size: 14px;
  line-height: 32px;
  -webkit-text-fill-color: black;
}

.v-text-field--filled:not(.v-text-field--single-line) input {
  margin-top: 5px !important;
}

input:-webkit-autofill::first-line {
  font-size: 14px;
}
</style>
