<template>
  <v-form ref="form" @submit.prevent="submit" lazy-validation v-model="valid">
    <v-text-field
      :label="$t('users.email')"
      name="email"
      v-model="email"
      type="email"
      outlined
      disabled
      autocomplete="off"
      large
    ></v-text-field>

    <v-text-field
      :label="$t('login.password')"
      name="password"
      v-model="password"
      :type="showPassword ? 'text' : 'password'"
      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
      @click:append="showPassword = !showPassword"
      :disabled="loading"
      outlined
      @focus="handleType"
      @blur="handleType"
      autocomplete="new-password"
      :rules="passwordRules"
      large
      counter="50"
      maxlength="50"
    ></v-text-field>

    <v-text-field
      :label="$t('login.confirm_password')"
      name="password-verify"
      v-model="passwordVerify"
      :type="showPasswordVerify ? 'text' : 'password'"
      :append-icon="showPasswordVerify ? 'mdi-eye' : 'mdi-eye-off'"
      @click:append="showPasswordVerify = !showPasswordVerify"
      :disabled="loading"
      outlined
      :rules="passwordVerifyRules"
      large
      counter="50"
      maxlength="50"
    ></v-text-field>

    <v-alert v-show="errorResetPasswordStatus" text color="red" dark dense>{{
      errorResetPasswordMsg
    }}</v-alert>

    <v-btn
      depressed
      type="submit"
      :loading="loading"
      :disabled="loading || !valid"
      color="primary"
      class="white--text mb-5"
      block
      large
    >
      {{ $t("login.reset_password") }}
    </v-btn>

    <v-btn
      depressed
      text
      :disabled="loading"
      color="grey darken-2"
      exact
      @click="goToLogin"
      block
    >
      {{ $t("login.return_to_login_btn") }}
    </v-btn>
  </v-form>
</template>

<script>
export default {
  data: () => ({
    email: null,
    token: null,
    valid: false,
    loading: false,
    password: null,
    passwordVerify: null,
    showPasswordVerify: false,
    showPassword: false,
    errorResetPasswordStatus: false,
    errorResetPasswordMsg: "",
    passwordRules: [
      (value) => !!value || "Campo obrigatório",
      (value) => (value && value.length >= 6) || "Minimo de 6 caracteres",
    ],
    fieldTypes: {
      password: "text",
    },
  }),
  computed: {
    passwordVerifyRules() {
      return [
        (value) => !!value || "Campo obrigatório",
        (value) => (value && value.length >= 6) || "Minimo de 6 caracteres",
        this.password === this.passwordVerify ||
          "As palavras-passe não coincidem",
      ];
    },
  },
  mounted() {
    this.email = this.$route.query.email;
    this.$refs.form.validate();
  },
  methods: {
    handleType(event) {
      const { srcElement, type } = event;
      const { name, value } = srcElement;

      if (type === "blur" && !value) {
        this.fieldTypes[name] = "text";
      } else {
        this.fieldTypes[name] = "password";
      }
    },
    goToLogin() {
      this.$router.push("/login");
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.errorResetPasswordStatus = false;
        this.errorResetPasswordMsg = "";

        this.$store
          .dispatch("RESET_PASSWORD", {
            email: this.email,
            token: this.$route.query.token,
            password: this.password,
          })
          .then((res) => {
            if (res) {
              if (res.error) {
                this.errorResetPasswordStatus = res.error;
                this.errorResetPasswordMsg = res.msg;
                this.loading = false;
                return;
              }

              this.$emit("success");
            }
          })
          .catch(() => {
            this.errorResetPasswordStatus = true;
            this.errorResetPasswordMsg =
              "O sistema não conseguiu validar o seu email. Por favor, tente novamente";
            this.loading = false;
          });
      }
    },
  },
};
</script>
